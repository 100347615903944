<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="0" tab="文章列表">
      <table-page :config="config" />
    </a-tab-pane>
    <a-tab-pane key="1" tab="文章推荐">
      <table-page :config="configRecommend" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
import { strategys, informations } from '@/utils/public';

export default {
  name: 'strategyInfoDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: '0',
      config: {
        checkbox: true,
        dataUrl: `/article/v2/list`,
        filter: {
          controls: []
        },
        columns: [
          {
            key: 'articleType',
            title: '文章类型',
            width: '130px',
          },
          {
            key: 'title',
            title: '标题',
            width: '150px',
          },
          {
            key: 'classification',
            title: '分类',
            width: '130px',
          },
          {
            key: 'source',
            title: '来源',
            width: '130px',
          },
          {
            key: 'relatedInstitutionName',
            title: '相关机构',
            width: '130px',
          },
          {
            key: 'relatedCollegeName',
            title: '相关院校',
            width: '130px',
          },
          {
            key: 'createdDate',
            title: '创建时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'lastModifiedDate',
            title: '更新时间',
            width: '130px',
            type: 'datetime'
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '100px'
          }
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'add',
            text: '新建文章',
            icon: 'plus',
            type: 'form',
            permission: '/article/v2/add',
            config: {
              color: 'primary',
              title: '添加文章',
              initUrl: '/article/getAllCountryAndColleges',
              submitUrl: '/article/v2/add',
              submitText: '确认添加',
              controls: [
                {
                  key: 'cover',
                  label: '封面',
                  required: true,
                  tips: "建议尺寸240x182 ；请上传大小不超过5mb的图片。",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/article/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000 * 5
                  }
                },
                {},
                {
                  key: 'articleType',
                  label: '文章类型',
                  type: 'radio',
                  config: {
                    initialValue: '攻略',
                    options: [
                      {
                        text: '攻略',
                        value: '攻略'
                      },
                      {
                        text: '资讯',
                        value: '资讯'
                      },
                    ]
                  }
                },
                {
                  key: 'classification',
                  label: '分类',
                  type: 'select',
                  required: true,
                  isAssociate: true,
                  config: {
                    options: strategys,
                    hidden({ selectedRows, form, initData }) {
                      if (form.articleType == '攻略') {
                        return false;
                      } else {
                        return true;
                      }
                    },
                  }
                },
                {
                  key: 'classification',
                  label: '分类',
                  type: 'select',
                  required: true,
                  isAssociate: true,
                  config: {
                    options: informations,
                    hidden({ selectedRows, form, initData }) {
                      if (form.articleType == '资讯') {
                        return false;
                      } else {
                        return true;
                      }
                    },
                  }
                },
                {
                  key: 'title',
                  label: '标题',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 30, message: '请尽量精简内容，输入30字以内' }
                    ]
                  }
                },
                {
                  key: 'source',
                  label: '来源',
                  type: 'text',
                  tips: "非原创文章请注明出处",
                  config: {
                    rules: [
                      { min: 1, max: 10, message: '请尽量精简内容，输入10字以内' }
                    ]
                  }
                },
                {
                  key: 'relatedCountry',
                  label: '相关国家',
                  type: 'souSelect',
                  config: {
                    options: 'countryList'
                  }
                },
                {
                  key: 'relatedCollege',
                  label: '相关院校',
                  type: 'souSelect',
                  config: {
                    options: 'collegeList'
                  }
                },
                {
                  key: 'introduction',
                  label: '摘要',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 100, message: '请尽量精简内容，输入100字以内' }
                    ]
                  }
                },
                {
                  key: 'content',
                  label: '正文',
                  type: 'editor',
                  required: true,
                  config: {
                    uploadUrl: '/api/article/addUpload'
                  }
                },
              ]
            }
          },
          {
            key: 'edit',
            text: '删除',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/article/v2/delete',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条文章，是否全部删除? `
            },
            config: {
              title: '删除成功',
              submitUrl: '/article/v2/delete',
              submitText: '确认',
              // otherParam: 'articleType',
              controls: []
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'add',
            text: '编辑',
            icon: 'plus',
            type: 'form',
            permission: '/article/v2/edit',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: '/article/edit/get',
              // initOther: 'type',
              submitUrl: '/article/v2/edit',
              submitText: '保存',
              controls: [
                {
                  key: 'cover',
                  label: '封面',
                  required: true,
                  tips: "建议尺寸240x182 ；请上传大小不超过5mb的图片。",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/article/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000 * 5
                  }
                },
                {},
                {
                  key: 'articleType',
                  label: '文章类型',
                  type: 'radio',
                  config: {
                    // initialValue: '攻略',
                    disabled: true,
                    options: [
                      {
                        text: '攻略',
                        value: '攻略'
                      },
                      {
                        text: '资讯',
                        value: '资讯'
                      },
                    ]
                  }
                },
                {
                  key: 'classification',
                  label: '分类',
                  type: 'select',
                  required: true,
                  isAssociate: true,
                  config: {
                    options: strategys,
                    hidden({ selectedRows, form, initData }) {
                      if (form.articleType == '攻略') {
                        return false;
                      } else {
                        return true;
                      }
                    },
                  }
                },
                {
                  key: 'classification',
                  label: '分类',
                  type: 'select',
                  required: true,
                  isAssociate: true,
                  config: {
                    options: informations,
                    hidden({ selectedRows, form, initData }) {
                      if (form.articleType == '资讯') {
                        return false;
                      } else {
                        return true;
                      }
                    },
                  }
                },
                {
                  key: 'title',
                  label: '标题',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 30, message: '请尽量精简内容，输入30字以内' }
                    ]
                  }
                },
                {
                  key: 'source',
                  label: '来源',
                  type: 'text',
                  tips: "非原创文章请注明出处",
                  config: {
                    rules: [
                      { min: 1, max: 10, message: '请尽量精简内容，输入10字以内' }
                    ]
                  }
                },
                {
                  key: 'relatedCountry',
                  label: '相关国家',
                  type: 'souSelect',
                  config: {
                    options: 'countryList'
                  }
                },
                {
                  key: 'relatedCollege',
                  label: '相关院校',
                  type: 'souSelect',
                  config: {
                    options: 'collegeList'
                  }
                },
                {
                  key: 'introduction',
                  label: '摘要',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 100, message: '请尽量精简内容，输入100字以内' }
                    ]
                  }
                },
                {
                  key: 'content',
                  label: '正文',
                  type: 'editor',
                  required: true,
                  config: {
                    uploadUrl: '/api/article/addUpload'
                  }
                },
              ]
            }
          },
        ]
      },
      configRecommend: {
        checkbox: true,
        dataUrl: `/article/articleRecommendation/list`,
        filter: {
          controls: [
          ]
        },
        columns: [
          {
            key: 'articleType',
            title: '文章类型',
            width: '130px',
          },
          {
            key: 'title',
            title: '标题',
            width: '130px',
          },
          {
            key: 'classification',
            title: '分类',
            width: '130px',
          },
          {
            key: 'relatedInstitution',
            title: '相关机构',
            width: '130px',
          },
          {
            key: 'recommendedLocation',
            title: '推荐位',
            width: '130px',
          },
          {
            key: 'sort',
            title: '排序',
            width: '130px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '100px'
          }
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'add',
            text: '新建推荐文章',
            icon: 'plus',
            type: 'form',
            permission: '/article/articleRecommendation/add',
            config: {
              color: 'primary',
              title: '新建推荐文章',
              initUrl: '/article/articleRecommendation/getAllArticles',
              submitUrl: '/article/articleRecommendation/add',
              submitText: '确认添加',
              controls: [
                {
                  key: 'recommendedLocation',
                  label: '推荐位置',
                  type: 'radio',
                  required: true,
                  config: {
                    initialValue: '图文banner',
                    options: [
                      {
                        text: '图文banner',
                        value: '图文banner'
                      },
                      {
                        text: '热点排行',
                        value: '热点排行'
                      },
                    ]
                  }
                },
                {
                  key: 'banner',
                  label: '封面',
                  tips: "建议尺寸403x208；大小不超过5mb",
                  type: 'file',
                  isAssociate: true,
                  required: true,
                  config: {
                    uploadUrl: '/api/article/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000 * 5,
                    hidden({ selectedRows, form, initData }) {
                      if (form.recommendedLocation == '图文banner') {
                        return false;
                      } else {
                        return true;
                      }
                    },
                  }
                },
                {
                  key: 'articleId',
                  label: '标题',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'articleList'
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'edit',
            text: '删除',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条件
            permission: '/article/articleRecommendation/delete',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条文章推荐，是否全部删除? `
            },
            config: {
              title: '删除成功',
              submitUrl: '/article/articleRecommendation/delete',
              submitText: '确认',
              controls: []
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
        {
            key: 'add',
            text: '编辑',
            icon: 'plus',
            type: 'form',
            permission: '/article/articleRecommendation/edit',
            config: {
              color: 'primary',
              title: '编辑推荐文章',
              initUrl: '/article/articleRecommendation/edit/get',
              submitUrl: '/article/articleRecommendation/edit',
              submitText: '保存',
              controls: [
                {
                  key: 'recommendedLocation',
                  label: '推荐位置',
                  type: 'radio',
                  required: true,
                  config: {
                    initialValue: '图文banner',
                    options: [
                      {
                        text: '图文banner',
                        value: '图文banner'
                      },
                      {
                        text: '热点排行',
                        value: '热点排行'
                      },
                    ]
                  }
                },
                {
                  key: 'banner',
                  label: '封面',
                  tips: "建议尺寸403x208；大小不超过5mb",
                  type: 'file',
                  isAssociate: true,
                  required: true,
                  config: {
                    uploadUrl: '/api/article/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000 * 5,
                    hidden({ selectedRows, form, initData }) {
                      if (form.recommendedLocation == '图文banner') {
                        return false;
                      } else {
                        return true;
                      }
                    },
                  }
                },
                {
                  key: 'articleId',
                  label: '标题',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'articleList'
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {}
              ]
            }
          },
        ]
      },
    };
  }

};
</script>

<style lang="less" scoped></style>